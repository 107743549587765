import I18n from 'i18n-js';
import TaskAssignation from '../utils/TaskAssignation';

export default class BackofficeTasksShow {
  constructor() {
    this.bindEvents();
    this.initDropify();
    this.taskAssignation = new TaskAssignation();
  }

  bindEvents() {
    const self = this;

    $(".show-comments").on('click', this.showComments);
    $(".show-files").on('click', this.showFiles);

    $(".comment-visibility-checkbox").on('change', this.changeItemVisibility);

    $(".show-file-btn").on('click', this.showFileModal);

    $("#process-laucher").on('click', this.launchProcessModal);

    // Move forward event handler
    $(".move-forward").on('click', function() {
      self.handleStateUpdate($(this), 'bg-success text-white', 'bg-warning text-white', ".next-step-alert", ".previous-step-alert", 'url-forward');
    });

    // Move backward event handler
    $(".move-backward").on('click', function() {
      self.handleStateUpdate($(this), 'bg-warning text-white', 'bg-success text-white', ".previous-step-alert", ".next-step-alert", 'url-backward');
    });

    // Original item click event handler
    $(".original").on('click', function() {
      var $li = $(this);
      $li.addClass('bg-info text-white').removeClass('bg-soft-success bg-soft-warning')
         .siblings(".aside").removeClass('bg-warning bg-success text-white');
      
      $(".next-step-alert").addClass("d-none");
      $(".previous-step-alert").addClass("d-none");

      var $updateBtn = $(".update-process-btn");
      self.updateButtonState($updateBtn, "#", true);
    });

       $(".owners-select").each(function() {
      $(this).select2({
        placeholder: "Choisir un ou plusieurs propriétaires",
        allowClear: true,
        closeOnSelect: false,
        multiple: true
      });
    });
  }

  handleStateUpdate($li, addClass, removeClass, alertToShow, alertToHide, urlData) {
    const self = this;
    
    $li.addClass(addClass).siblings(".aside").removeClass(removeClass);
    
    var $ogLi = $li.siblings(".original");
    $ogLi.removeClass('bg-info text-white bg-soft-warning bg-soft-success').addClass(addClass.replace('bg-', 'bg-soft-'));
  
    $(alertToHide).addClass("d-none");
    $(alertToShow).removeClass("d-none");
  
    var $updateBtn = $(".update-process-btn");
    self.updateButtonState($updateBtn, $updateBtn.data(urlData));
  }

  updateButtonState(updateBtn, url, disabled = false) {
    updateBtn.toggleClass('disabled', disabled);
    updateBtn.attr('href', url);
  }

  showComments() {
    $("#comments-section").removeClass("d-none").addClass("d-flex");
    $("#files-section").removeClass("d-flex").addClass("d-none");

    $(".show-comments").addClass("underline");
    $(".show-files").removeClass("underline")
  }

  showFiles() {
    $("#comments-section").removeClass("d-flex").addClass("d-none");
    $("#files-section").removeClass("d-none").addClass("d-flex");

    $(".show-comments").removeClass("underline")
    $(".show-files").addClass("underline")
  }

  showFileModal() {
    let title = $(this).data('file-title');
    let url = $(this).data('file-url');

    $("#file-modal-title").text(title);
    
    $("#embed-wrapper").empty();
    if (url.endsWith('.pdf')) {
      $("#embed-wrapper").append(`<embed src="${url}" type="application/pdf" width="100%" height="600px" />`);
    } else if (url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.png')) {
      $("#embed-wrapper").append(`<img src="${url}" class="img-fluid" />`);
    } else {
      $("#embed-wrapper").append(`<iframe src="${url}" width="100%" height="600px" />`);
    }

    $("#download-button").attr('href', url);
    $("#file-modal").modal('show'); 
  }

  changeItemVisibility() {
    const attachment_id = $(this).data('attachment-id');
    const item_type = $(this).data('item-type');
    const visibility = $(this).is(':checked');
    const url = $(this).data('url');

    $.ajax({
      url: url,
      method: 'PUT',
      data: { 
        item: {
          visibility: visibility,
          item_type: item_type,
          id: attachment_id
        }
      },
      success: function(data) {
        application.successNotification(I18n.t("backoffice.tasks.update_visibility_success"))
      },
      error: function(data) {
        application.errorNotification(I18n.t('backoffice.tasks.update_visibility_error'));
      }
    });
  }

  initDropify() {
    $(".dropify").dropify({
      messages: {
      'default': I18n.t('plugins.dropify.messages.default'),
      'replace': I18n.t('plugins.dropify.messages.replace'),
      'remove': I18n.t('plugins.dropify.messages.remove'),
      'error': I18n.t('plugins.dropify.messages.error')
      },
      error: {
      'fileSize': I18n.t('plugins.dropify.error.fileSize', { value: '2MB' }),
      'minWidth': I18n.t('plugins.dropify.error.minWidth'),
      'maxWidth': I18n.t('plugins.dropify.error.maxWidth'),
      'minHeight': I18n.t('plugins.dropify.error.minHeight'),
      'maxHeight': I18n.t('plugins.dropify.error.maxHeight'),
      'imageFormat': I18n.t('plugins.dropify.error.imageFormat'),
      'fileExtension': I18n.t('plugins.dropify.error.fileExtension'),
    }
    });
  }

  launchProcessModal() {
    const taskId = $(this).data('task');

    
  }

  onDataChanged(data) {
  }

  onDestroy() {
    $(".dropify").dropify("destroy");
  }
}